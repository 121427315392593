import { ReactComponent as SutaaEnglish } from 'assets/sutaaEnglish.svg';
import { ReactComponent as SutaaJapanese } from 'assets/sutaaJapanese.svg';
import { GridBackground } from 'components/gridBackground';
import Styles from './heroSection.module.scss';

export const HeroSection = () => {
	return (
		<div className={Styles.container}>
			<GridBackground showAnimation={true} />
			<div className={Styles.brand}>
				<SutaaEnglish className={Styles.brandEnglish} />
				<SutaaJapanese className={Styles.brandJapanese} />
			</div>
		</div>
	);
};
