import React, { useEffect, useState } from 'react';
import Styles from './gridBackground.module.scss';

export const GridBackground = ({ showAnimation = false }) => {
	const [squaresData, setSquaresData] = useState([]);

	const numRows = Math.ceil(window.innerHeight / 34);
	const numCols = Math.ceil(window.innerWidth / 34);

	const squares = Array.from({ length: numRows * numCols });

	useEffect(() => {
		const centerX = Math.floor(numCols / 2);
		const centerY = Math.floor(numRows / 2);
		const maxDistance = Math.sqrt(centerX ** 2 + centerY ** 2);

		const newSquaresData = squares.map((_, index) => {
			const row = Math.floor(index / numCols);
			const col = index % numCols;

			const distance = Math.sqrt((row - centerY) ** 2 + (col - centerX) ** 2);

			const opacity = 0.98 - distance / maxDistance;

			return { distance, opacity };
		});

		setSquaresData(newSquaresData);
	}, [numRows, numCols, squares]);

	return (
		<div className={Styles.gridContainer}>
			{squaresData.map(({ distance, opacity }, index) => (
				<div>
					<div
						key={index}
						className={`${showAnimation && Styles.gridSquareAnimated}`}
						style={{
							animationDelay: `${distance * 0.021}s`,
							'--square-opacity': opacity
						}} />
					<div className={Styles.gridSquare} />
				</div>
			))}
		</div>
	);
};
